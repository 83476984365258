@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Alice&family=Baloo+2:wght@400;800&family=Bree+Serif&family=Montserrat:ital,wght@0,500;0,700;1,700&display=swap');

s * {
  color: #ffff;
}

body {
  background-color: #181C2B;
  /* background-color: #2B2E43; */
  color: #ffff;
  font-family: 'Montserrat', sans-serif;
}

/* about */
.about {
  background-image: url("../public/img/header-bg.webp");
}

/* Banner */
.header-content::before {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-image: url(https://billionercyber.com/assets/img/header-bg-1.webp);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto;
  content: '';
  z-index: 5;
}

.header-content {
  background-image: url("../public/img/header-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 530px;
  overflow: hidden;
  position: relative;
  z-index: 20;
}

/* @media screen and (max-width:400px) {
  .header-content{
  }
} */

.header-content:after {
  z-index: -1;
  position: absolute;
  width: 3980px;
  height: 100%;
  left: 0;
  bottom: 0;
  background-image: url("../public/img/header-grid.png");
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: auto;
  content: '';
  -webkit-animation: grid 200s infinite linear;
  -moz-animation: grid 200s infinite linear;
  animation: grid 200s infinite linear;
  pointer-events: none;
}


.header-scene__item {
  width: 100%;
  height: 100%;
}

.header-scene__item img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.header-scene__item canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: .5;
  width: 250%;
  height: 150%;
}

.header-scene__item:nth-child(1) {
  z-index: 3;
}

.header-scene__item:nth-child(2) {
  z-index: 2;
}

.header-scene__item:nth-child(2) img {
  -webkit-animation: rotate 10s infinite linear;
  -moz-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
}

.header-scene__item:nth-child(3) {
  z-index: 1;
}

.header-scene__item:nth-child(3) img {
  -webkit-animation: rotateR 30s infinite linear;
  -moz-animation: rotateR 30s infinite linear;
  animation: rotateR 30s infinite linear;
}

.header-scene__item:nth-child(4) {
  z-index: 1;
}

@-webkit-keyframes rotateR {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-moz-keyframes rotateR {
  0% {
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -moz-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes rotateR {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
    -moz-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-webkit-keyframes grid {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-moz-keyframes grid {
  0% {
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes grid {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }
}


.container img {
  animation: slide 35s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-4500px, 0, 0);
    /* The image width */
  }
}